import React, { useState } from "react"
import Select from "react-select"
import { renderRichText } from "gatsby-source-contentful/rich-text"


const customStyles = {
  control: (base, state) => ({
    ...base,
    background: "transparent",
    border: "1px solid #fff",
    color: "red",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#bf1116" : "#bf1116",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#bf1116" : "#bf1116",
      boxShadow: state.isFocused ? null : null,
    },
  }),
  menu: base => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    border: "1px solid #bf1116",
    // kill the gap
    marginTop: 0,
  }),
  menuList: base => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    background: "#151515",
    color: "#bf1116",
  }),
}

const options = [
  { value: "General/Membership", label: "General/Membership" },
  { value: "Marketing", label: "Marketing" },
  { value: "Measurement", label: "Measurement" },
  { value: "Training & Tools", label: "Training" },
]

const ContactSec = ({ data }) => {
  const [values, setValues] = useState({
    department: "",
    name: "",
    email: "",
    subject: "",
    message: "",
  })
  const [errorContact, setError] = useState("")
  const [successContact, setSuccessContact] = useState(false)

  const { department, name, email, subject, message } = values

  const handleChange = e =>
    setValues({ ...values, [e.target.name]: e.target.value })

  const onChange = selectedOption => {
    setValues({ ...values, department: selectedOption })
    // console.log(`Option selected:`, selectedOption);
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setError("")
    setSuccessContact(false)

    if (!department.value || !name || !email || !subject || !message) {
      setError("All fields are required")
      return
    }

    if (!validateEmail(email)) {
      setError("Please provide a valid email")
      return
    }
    try {
      const res = await fetch("/.netlify/functions/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
      if (res.ok) {
        setSuccessContact(true)
        document.querySelector("form.contactPageForm").classList.add("formSubmitted");
        document.querySelector("form.contactPageForm").disabled = true;
      }
    } catch (err) {
      console.log(err)
      setError("Something went wrong")
    }
  }


  return (
    <section className="contactSec">
      <div className="leftSideBar">
        <div className="upper-col">
          <h1>{data.content.content}</h1>
        </div>
        <div className="lower-col">
          <h6>{data.office}</h6>
          {renderRichText(data.address)}
        </div>
      </div>

      <form onSubmit={handleSubmit} className="contactPageForm">
        <Select
          options={options}
          className={`customSelect custom-option contactDropdown`}
          styles={customStyles}
          classNamePrefix={`contactDropdown`}
          required
          placeholder={`Department: *`}
          value={department}
          onChange={onChange}
        />
        <input
          type="text"
          className="hidden"
          name="department"
          value={department.value}
        />
        <input
          type="text"
          placeholder="Name *"
          required
          className="textInput"
          name="name"
          value={name}
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="Email *"
          required
          className="textInput"
          name="email"
          value={email}
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="Subject: *"
          required
          className="textInput"
          name="subject"
          value={subject}
          onChange={handleChange}
        />
        <textarea
          row="4"
          placeholder="Your Message"
          name="message"
          value={message}
          onChange={handleChange}
        />
        <button className="submit"><span className="submitText">Submit</span>
          {successContact && (<span className="thankYouMsg">
            Thank you for contacting us! We'll be in touch
            with you soon.</span>)}
        </button>
      </form>
    </section>
  )
}

export default ContactSec


