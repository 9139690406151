import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import ContactSec from "../components/contact/contact.js"
import Hero from "../components/contact/hero.js"

const Contact = ({ data }) => (
  <div className="contactPage">
    <Layout>
      <Seo title="Contact COMMB" />
      <Hero data={data.contentfulContact} />
      <div className="contactSecWrapper">
        <ContactSec data={data.contentfulContact} />
      </div>
    </Layout>
  </div>
)

export default Contact

export const query = graphql`
  query Contact($language: String) {
    contentfulContact(node_locale: { eq: $language }) {
      title
      content {
        content
      }
      office
      address {
        raw
      }
    }
  }
`